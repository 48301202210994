import React from 'react';
import Helmet from 'react-helmet';
import Header from '../Header';
import Footer from '../Footer';
import '../../sass/global/styles.scss';

import FontUrl2 from "../../fonts/Korolev-Bold.woff2"
import FontUrl4 from "../../fonts/KorolevCompressed-Medium.woff2"
import FontUrl5 from "../../fonts/KorolevCompressed-Heavy.woff2"

const Layout = ({ children, location, wordpressUrl, includeCss = false }) => (
  <div id="layout">
    <Helmet>
      <link rel="preload" as="font" href={FontUrl2} type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" as="font" href={FontUrl4} type="font/woff2" crossOrigin="anonymous" />
      <link rel="preload" as="font" href={FontUrl5} type="font/woff2" crossOrigin="anonymous" />
    </Helmet>
    <Header location={location} />
    <div className="main">{children}</div>
    <Footer location={location} />
  </div>
)

export default Layout
