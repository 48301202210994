import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { FaFacebookF, FaTwitter, FaInstagram, FaYoutube, FaLinkedinIn, FaPinterest, FaEnvelope, FaPhone, FaMapPin } from 'react-icons/fa';

class PassSocialLinks extends Component {

  render () {
    const { data, share } = this.props;
    const { siteSettings } = data;
    const { socialLinks } = siteSettings.options;
    if (share && typeof window !== 'undefined') {
      return (
        <div className="social-links">
          <ul>
            <li>
              <a
                className="facebook"
                href={`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
              </a>
            </li>
            <li>
              <a
                className="linkedin"
                href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&title=&summary=&source=`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a
                className="twitter"
                href={`http://twitter.com/share?url=${window.location.href}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </a>
            </li>
          </ul>
        </div>
      );
    }
    return (
      <div className="social-links">
        <ul>
          {socialLinks && socialLinks.map((item, index) => (
            <li key={index} >
              <a
                className={item.type}
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
              >
               {item.type === 'facebook' && <FaFacebookF />}
               {item.type === 'twitter' && <FaTwitter />}
               {item.type === 'instagram' && <FaInstagram />}
               {item.type === 'youtube' && <FaYoutube />}
               {item.type === 'linkedin' && <FaLinkedinIn />}
               {item.type === 'pinterest' && <FaPinterest />}
              </a>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export const SocialLinks = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              socialLinks {
                type
                url
              }
            }
          }
        }
      `}
      render={data => <PassSocialLinks {...props} data={data} />}
    />
  );
}
