import React, { Component, Fragment } from 'react';
import { FaChevronDown } from 'react-icons/fa';
import GatsbyLink from '../GatsbyLink';
import { ImagePass } from '../Image/ImagePass';
import './HeaderNav.scss'
import { decodeEntities } from '../../utils/helpers';

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, setParentHovered, loadImages, handleLoadImages } = props;
  const {
    slug,
    object_id,
    title,
    url,
    children,
    target,
    image,
    description
  } = item;
  const isOpen = openNavKey === object_id;
  return (
    <span
      key={object_id}
      onMouseEnter={() => setParentHovered(children && children[0].url !== '#gatsby' ? true : false)}
      onMouseLeave={() => setParentHovered(false)}
      onMouseOver={() => setParentHovered(children && children[0].url !== '#gatsby' ? true : false), event => handleLoadImages(event)}
      onFocus={() => setParentHovered(children && children[0].url !== '#gatsby' ? true : false)}
    >
      <GatsbyLink
        to={url}
        onClick={enableBodyScroll}
        target={target ? target : null}
        activeClassName="active"
        aria-label={title}
        // decode
      >
        <span>{decodeEntities(title)}</span>
      </GatsbyLink>
      {children && children[0].url !== '#gatsby' && (
        <React.Fragment>
          <div className="meganav">
            <div className="wrap">
              <div className="meganav-content">
                <span className="meganav-content-title">{decodeEntities(title)}</span>
                <div className="meganav-content-description"><span>{decodeEntities(description)}</span></div>
              </div>
              <NavChildren baseUrl={baseUrl} childNav={children} isOpen={isOpen} />
              <div className="meganav-image">
                {image && loadImages && (
                  <div className="image-container">
                    <ImagePass src={image} position="relative" />
                  </div>
                )}
              </div>
            </div>
          </div>
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <FaChevronDown />
          </button>
        </React.Fragment>
      )}
    </span>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl, isOpen = null, loadImages } = props;
  return (
    <ul className={`children ${isOpen ? 'open' : 'close'}`}>
      {childNav.map((child, index) => {
        const {
          classes,
          object_id,
          slug,
          title,
          url,
          target
        } = child;
        return (
          <li
            key={index}
            >
            <GatsbyLink to={url} onClick={enableBodyScroll} target={target ? target : null} activeClassName="active" aria-label={title} decode={true}>{title}</GatsbyLink>
          </li>
        )
      })}
    </ul>
  );
}

const SERVICES_TYPE = 'services-nav';
const COMMUNCIATIONS_TYPE = 'communication-nav';
const COMPANY_TYPE = 'company-nav';

export default class HeaderNav extends Component {
  state = { openNavKey: null, loadImages: null };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  handleLoadImages = (event) => {
    const { resetHoverClear } = this.props
    if (!this.state.loadImage) {
      this.setState({loadImages: true})
    }
  }

  render() {
    const { active, navItems, baseUrl, className, setParentHovered } = this.props;
    const { openNavKey, loadImages } = this.state;
    return (

        <ul className={className}>
          {navItems && navItems.map((col, index) => index < 10 && (
            <li
              key={index}
            >
              <NavItem
                key={col.url}
                item={col}
                baseUrl={baseUrl}
                toggleSubNav={this.toggleSubNav}
                openNavKey={openNavKey}
                setParentHovered={setParentHovered}
                loadImages={loadImages}
                handleLoadImages={this.handleLoadImages}
              />
            </li>
          ))}
        </ul>

    );
  }
}
