import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Logo } from '../../../theme/LogoFooter';
import GatsbyLink from '../GatsbyLink';
import { SocialLinks } from '../SocialLinks';
import { decodeEntities } from '../../utils/helpers';
import './Footer.scss';


class Footer extends Component {
  sendEmail(event){
    event.preventDefault()
    const { data } = this.props
    const { email } = data.siteSettings.options.locations[0]
      window.location = `mailto:${decodeEntities(email)}`;
  }

  render() {
    const { data, location = {} } = this.props;
    const {
      legalNavigation,
      footerNavigation,
      siteSettings,
      wordpressWpSettings
    } = data;
    const { items: legalNav } = legalNavigation;
    const { items: footerNav } = footerNavigation;
    const {
      email,
      phone,
      addressDisplay
    } = siteSettings.options.locations[0];
    const currentYear = (new Date()).getFullYear();
    const isHome = location.pathname === '/';
    const BirdBrainLink = () => (
      isHome ? (
        <a
          href="https://distl.com.au/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Distl
        </a>
      ) : 'Distl'
    );
    return (
      <footer>
        <div className="footer-main">
          <div className="top">
            <div className="wrap">
              <Logo />
              <div className="tagline">
                <span>VALUE THROUGH EXPERIENCE</span>
                {/* <img src="/images/tagline.png" alt="footer tagline" /> */}
              </div>
            </div>
          </div>
          <div className="inner">
            <div className="wrap">
              <div className="contact-details">
                {siteSettings.options.locations.map((loc, index) => {
                  const { addressStreet, addressCity, addressRegion, addressPostCode } = loc;
                  return <div key={index} className="address"><span>{decodeEntities(addressStreet)}</span><span>{decodeEntities(addressCity)} {decodeEntities(addressRegion)} {decodeEntities(addressPostCode)}</span></div>
                })}
                {email && (
                  <div className="email">
                    <a href="#" onClick={(event) => this.sendEmail(event)}>
                      <span dangerouslySetInnerHTML={{__html: email}} />
                    </a>
                  </div>
                )}
                {phone && (
                  <div className="phone">
                    <a
                      href={`tel:${phone.replace(/[^A-Z0-9]/ig, "")}`}
                      className='control-call'
                      aria-label="Call Now"
                    >
                      {phone}
                    </a>
                  </div>
                )}
                <div className='social-links-wrap'>
                  <span>Connect with us</span>
                  <SocialLinks />
                </div>
              </div>
              <ul className="footer-nav">
                {footerNav.map((item, index) => {
                  const { children } = item;
                  return (
                    <li key={index}>
                      <GatsbyLink to={item.url} aria-label={item.title} decode={true}>{item.title}</GatsbyLink>
                      {children && (
                        <ul>
                          {children.map((child, index) => {
                            return (
                              <li key={index}>
                                <GatsbyLink to={child.url} aria-label={child.title} decode={true}>{child.title}</GatsbyLink>
                              </li>
                            )
                          })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className="disclaimers">
          <div className="wrap">
            <div className="copyright">
              <span>©{currentYear} <strong>{wordpressWpSettings.title}</strong>, All rights reserved</span>
            </div>
            {legalNav && (
              <div className="navigation">
                <ul>
                  {legalNav.map((item, index) => (
                    <li key={index}><GatsbyLink to={item.url} decode={true}>{item.title}</GatsbyLink></li>
                  ))}
                </ul>
                <span>Website by <BirdBrainLink /></span>
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        legalNavigation: wordpressMenusMenusItems(slug: { eq: "legal-navigation" }) {
          items {
            title
            url
            classes
            slug
          }
        }
        footerNavigation: wordpressMenusMenusItems(slug: { eq: "footer-navigation" }) {
          items {
            title
            url
            classes
            slug
            object_id
            target
            children: child_items {
              title
              url
              classes
              object_id
              slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              addressStreet
              addressCity
              addressRegion
              addressPostCode
              email
              phone
            }
          }
        },
        wordpressWpSettings {
          title
        }
      }
    `}
    render={data => <Footer data={data} {...props} />}
  />
)
