import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Headroom from 'react-headroom';
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav';
import { SearchIcon } from '../../images/icons/SearchIcon';
import GatsbyLink from '../GatsbyLink'
import './Header.scss';

const isClient = typeof window !== 'undefined';

class Header extends Component {
  state = {
    navActive: false,
    parentIsHovered: false
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.handleResize);
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (window.innerWidth > 1080) {
      if(isClient) {
        let childrenDomElements = document.querySelectorAll('.children');
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav();
        }
      }
    }
  }

  toggleNav = event => {
    const { navActive } = this.state;
    if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open');
      }
      this.setState({
        navActive: true,
        searchActive: false,
      });
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open');
      }
      this.setState({ navActive: false });
    }
  };

  closeAll = event => {
    if (event) event.preventDefault();
    if (isClient) {
      document.body.classList.remove('nav-open');
    }
    this.setState({
      searchActive: false,
      navActive: false,
    });
  };

  setParentHoveredState = (parentIsHovered) => {
    this.setState({ parentIsHovered });
  }

  render() {
    const {
      searchActive,
      navActive,
      parentIsHovered
    } = this.state;
    const {
      data,
    } = this.props;
    const {
      primaryNavigation,
      wordpressWpSettings
    } = data;
    const { baseUrl } = wordpressWpSettings;
    const { items: navItems } = primaryNavigation;
    return (
      <Headroom disableInlineStyles={true} pinStart={50} className={parentIsHovered ? 'parent-hovered' : ''}>
        <header>
          <div className="header-main">
            <div className="wrap">
              <div className="inner">
                <Logo />
                <div className="nav">
                  <nav className={`nav-inner${navActive ? ' active' : ''}`}>
                    <HeaderNav
                      active={navActive}
                      className="navigation"
                      navItems={navItems}
                      baseUrl={baseUrl}
                      toggleNav={this.toggleNav}
                      setParentHovered={this.setParentHoveredState}
                    />
                  </nav>
                </div>
                <div className="header-controls">

                  <GatsbyLink
                    className={`control-search icon-search ${searchActive ? 'active' : ''}`}
                    to="/search/"
                    aria-label="Toggle Search"
                  ><SearchIcon strokeColor="#000"/></GatsbyLink>

                  <button
                    type="button"
                    className={`control-nav icon-hamburger ${navActive ? 'active' : ''}`}
                    onClick={this.toggleNav}
                    aria-label="Toggle Menu"
                  >
                    <span className={`burger ${navActive ? 'active' : ''}`} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </header>
      </Headroom>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        primaryNavigation: wordpressMenusMenusItems(slug: { eq: "primary-navigation" }) {
          items {
            title
            url
            classes
            slug
            object_id
            target
            description
            image {
              localFile {
                childImageSharp {
                  fixed(width: 480) {
                    ... GatsbyImageSharpFixed
                  }
                }
              }
            }
            children: child_items {
              title
              url
              classes
              object_id
              slug
              target
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            locations {
              addressDisplay
              email
              phone
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
